<template lang="pug">
div
  .row
    .col-8
      om-body-text(bt400lg) {{ $t('codeInsertV2.help.askUsForHelp.text1') }}
        br
        | {{ $t('codeInsertV2.help.askUsForHelp.text2') }}
        br
        | {{ $t('codeInsertV2.help.askUsForHelp.text3') }}
      om-button.mt-4(
        primary
        icon="comment-dots"
        :disabled="!hasHubSpot"
        :title="startChatTitle"
        @click="startChat"
      ) {{ $t('codeInsertV2.help.askUsForHelp.startChat') }}
    .col-4.d-flex.justify-content-end.align-items-start
      img.insert-code-ask-us-illustration(
        :src="require('@/assets/admin/img/insert-code/reka_arvai_portrait.png')"
      )
  .row
    .col-12
      hr
  .row
    .col-12
      om-body-text(bt400md) {{ $t('codeInsertV2.help.askUsForHelp.description') }}
      ShareWebsiteCredentials.mt-5(ref="shareLogin")
</template>

<script>
  import { track } from '@/services/xray';

  export default {
    components: { ShareWebsiteCredentials: () => import('./ShareWebsiteCredentials.vue') },
    computed: {
      hasHubSpot() {
        return !!window.HubSpotConversations;
      },
      startChatTitle() {
        if (!this.hasHubSpot) return this.$t('codeInsertV2.help.askUsForHelp.problem');
        track('codeInsert-help-askUs-startChat', null);
        return this.$t('codeInsertV2.help.askUsForHelp.startChat');
      },
    },
    methods: {
      reset() {
        this.resetShareLogin();
      },
      resetShareLogin() {
        this.$refs?.shareLogin?.reset?.();
      },
      startChat() {
        window.HubSpotConversations.widget.open();
      },
    },
  };
</script>
